import {checkValid} from "../../../globalModels/functions/ModelsValidate";
// import {User} from "../../../globalModels/User";
// import {EXPENSES_SYSTEM_TYPES} from "../../../../staticData/staticVariables";
import {Files} from "../../../globalModels/Files";
import {EXPENSES_SYSTEM_TYPES} from "../../../../staticData/staticVariables";
const _ = require('lodash');


export function OtherIncome() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    type: null,
    country: null,
    total: '',
    comment: '',
    documentDate: '',

    Files: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),

    // User: new User(),
  }


  this.validation = {
    id: false,
    name: false,
    type: false,
    country: false,
    total: false,
    comment: false,
    documentDate: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
    type: '',
    country: '',
    total: '',
    comment: '',
    documentDate: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
    type: false,
    country: false,
    total: false,
    comment: false,
    documentDate: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getType = () => {
    return this.data.type
  }
  this.getCountry = () => {
    return this.data.country
  }
  this.getTotal = () => {
    return this.data.total
  }
  this.getComment = () => {
    return this.data.comment
  }
  this.getDocumentDate = () => {
    return this.data.documentDate
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setName = (val) => {
    this.data.name = val
  }
  this.setType = (val) => {
    this.data.type = val
  }
  this.setCountry = (val) => {
    this.data.country = val
  }
  this.setTotal = (val) => {
    this.data.total = val
  }
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setDocumentDate = (val) => {
    this.data.documentDate = val
  }

}

/**
 * Global Setters
 */

OtherIncome.prototype.setItem = function(val, countries = false) {
  this.setId(val.id)
  this.setName(val.name)
  this.setType(_.find(val.expense_types,
    function(elem) {return elem.type === EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE.value}))

  if (countries) {
    this.setCountry(countries.find(findItem => {
      return findItem.id === val.country.id
    }))
  }

  this.setTotal(val.amount)
  this.setComment(val.comment)
  this.setDocumentDate(val.document_date)

  // if(val?.user)
  //   this.data.User.setUser(val.user)
}


/**
 * Prepare Data
 */

OtherIncome.prototype.prepareSave = function(userId) {
  let data = {
    'user_id': userId,
    // 'name': this.getName(),
    'type': 'income',
    'amount': this.getTotal(),
    'comment': this.getComment(),
    'country_id': this.getCountry() ? this.getCountry().id : null,
    'document_date': this.getDocumentDate(),
    'expense_types': {
      'default_income_id': this.getType()?.id,
    }
  }

  // if(isAdmin){
  //   data.user_id = this.data.User.getUserId()
  // }

  return data
}

OtherIncome.prototype.prepareFileData = function(id) {
  let reader = new FormData();
  reader.append("file", _.first(this.data.Files.getFiles()).file);
  reader.append("expense_id", id);
  return reader
}


/**
 * Validations
 */

OtherIncome.prototype.firstValidation = function() {

  let validationItems = {
    // name: this.getName(),
    type: this.getType()?.id,
    country: this.getCountry(),
    total: this.getTotal(),
    comment: this.getComment(),
    documentDate: this.getDocumentDate(),
  }

  let validationOptions = {
    // name: {type: ['empty']},
    type: {type: ['empty']},
    country: {type: ['empty']},
    total: {type: ['empty', 'not-zero']},
    comment: {type: ['empty']},
    documentDate: {type: ['empty']},
  }

  // console.log();

  // if(isAdmin && !this.data.User.userValidation({userId: true}))
  //   return false


  return (this.checkValid(validationItems, validationOptions) && this.data.Files.fileSizeValidation())
}
