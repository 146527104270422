<template>
  <div class="fragment">

    <template v-if="$store.getters.getOtherIncome.length > 0 && $store.getters.getOtherIncomeLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'otherIncome_Id',
          'otherIncome_Created',
          'common_user',
          'otherIncome_Total',
          'otherIncome_comment',
          'common_country',
          'otherIncome_typeOfIncome',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th class="pr-0"></th>-->
            <th>{{$t('otherIncome_Id.localization_value.value')}}</th>
            <th>{{$t('otherIncome_Created.localization_value.value')}}</th>
            <th>{{$t('expenses_DocumentDate.localization_value.value')}}</th>
<!--            <th>{{$t('otherIncome_DocumentDate.localization_value.value')}}</th>-->
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th class="text-lg-right">{{$t('otherIncome_Total.localization_value.value')}}</th>
            <th>{{$t('otherIncome_comment.localization_value.value')}}</th>
            <th>{{$t('common_country.localization_value.value')}}</th>
            <th>{{$t('otherIncome_typeOfIncome.localization_value.value')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getOtherIncome" :key="index">
<!--            <td class="pr-0">-->
<!--              <DefaultCheckbox-->
<!--                      class="empty-label"-->
<!--                      :selectedNow="selectedNow"-->
<!--                      :dataValue="item.id"-->
<!--              />-->
<!--            </td>-->
            <td>
              <div class="table-link btn-style"
                @click="changeInfoPopup(true, item.id)"
              >
                #{{item.id}}
              </div>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              {{ item.document_date | moment("DD MMM, YYYY") }}
            </td>
<!--            <td>-->
<!--              {{ item.document_date | moment("DD MMM, YYYY") }}-->
<!--            </td>-->
            <td v-if="isAdmin">
              <template v-if="item.admin_user">
                <LinkButton
                    :value="item.admin_user.full_name"
                    :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.admin_user.id}`"
                />
              </template>
              <template>
                —
              </template>
            </td>
            <td align="right">
              <ValueHelper
                      :value="item.amount"
              />
            </td>
            <td>
              <div class="table-row">
                <TooltipTextHelper
                        :text="item.comment"
                        :paragraph="item.comment"
                        :fullTextWidth="'300'"
                />
              </div>
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'country.name'"
              />
            </td>
            <td>
              {{getExpenseTypeName(item, EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE.value)}}
            </td>
            <td>
              <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                             class="right"
              >
                <template slot="item">
                  <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      :link="$store.getters.GET_PATHS.incomeOtherIncomeEdit + '/' + item.id"
                  />
                </template>
                <template slot="item">
                  <LinkButton
                      :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                      @click.native="removeItem(item.id)"
                  />
                </template>
              </ManagerButton>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOtherIncome" :key="index"
            >
              <OtherIncomeMobileTable
                      :item="item"
                      @removeItem="removeItem(item.id)"
                      @changeInfoPopup="changeInfoPopup(true, item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getOtherIncomeCommonList.next_page_url !== null && $store.getters.getOtherIncome.length > 0"
                    @click.native="$emit('showMore')"
                    :count="$store.getters.getOtherIncomeCommonList.total - $store.getters.getOtherIncomeForPage * $store.getters.getOtherIncomeCommonList.current_page < $store.getters.getOtherIncomeForPage ?
                  $store.getters.getOtherIncomeCommonList.total - $store.getters.getOtherIncomeForPage * $store.getters.getOtherIncomeCommonList.current_page:
                  $store.getters.getOtherIncomeForPage"
            />
            <!--<ExportBtn-->
            <!--@click.native="$emit('downloadFiles')"-->
            <!--class="table-bottom-btn__right"-->
            <!--/>-->
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getOtherIncomeLoading === false && $store.getters.getOtherIncome.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <InfoPopup
      v-if="isModalInfoPopup"
      :itemId="itemId"
      @close="changeInfoPopup(false)"
    />

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import InfoPopup from "../../../../popups/InfoPopup/InfoPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../../../staticData/staticVariables";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import OtherIncomeMobileTable from "./OtherIncomeMobileTable/OtherIncomeMobileTable";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";


  export default {
    name: "OtherIncomeTable",

    components: {
      ManagerButton,
      OtherIncomeMobileTable,
      LinkButton,
      TooltipTextHelper,
      ValueHelper,
      NoResult,
      ShowMore,
      // DefaultCheckbox,
      InfoPopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: '',
        showFilter: false,
        isMobile: false,

        EXPENSES_SYSTEM_TYPES: EXPENSES_SYSTEM_TYPES,

        showContent: false,

        show1: false,
        show2: false,
        show3: false,

        isModalInfoPopup: false,
      }
    },


    methods: {

      changeInfoPopup(val, itemId = false){
        if(itemId){
          this.itemId = itemId
        } else {
          this.itemId = ''
        }
        this.isModalInfoPopup = val
      },

      getExpenseTypeName(item, systemType) {
        return this._.find(item.expense_types,
            function(elem) {return elem.type === systemType})?.name || '—'
      },

      removeItem(id){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOtherIncome', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let expenses = this.$store.getters.getOtherIncome
              expenses.map((item, index) => {
                if(item.id === id) {
                  expenses.splice(index, 1)
                }
              })

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    },

  }
</script>

<style lang="scss" scoped>

  .table-row.justify-content-end {
    transform: translateX(7px);
  }

  .mw-220 {
    max-width: 220px !important;
  }
</style>
