export const otherIncomeMixin = {

	methods: {

		save(edit = false) {
			console.log(333333);
			if(!this.OI.firstValidation()) return

			let saveType = 'createOtherIncome'

			let data = this.OI.prepareSave(this.$store.getters.getUserProfile.id)

			if (edit) {
				saveType = 'updateOtherIncome'
				data = {
					data: data,
					id: this.OI.getId()
				}
			}

			this.$store.dispatch(saveType, data).then((response) => {
				if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if(this.OI.data.Files.getFiles().length > 0){
							this.addFile(this.getRespData(response)?.id, edit)
							this.$router.push(this.$store.getters.GET_PATHS.otherIncome)
							return
						}

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
						}
						this.$router.push(this.$store.getters.GET_PATHS.otherIncome)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		addFile(id, edit) {
			if(id){
				let data = this.OI.prepareFileData(id)

				this.$store.dispatch('uploadFileOtherIncome', data).then(response => {
					if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
					switch (this.getResponseStatus(response)) {
						/**
						 * Success
						 */
						case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
							if (edit) {
								this.openNotify('success', 'common_notificationRecordChanged')
							} else {
								this.openNotify('success', 'common_notificationRecordCreated')
							}
							break
						}
						/**
						 * Validation Error
						 */
						case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
							let errors = response.response.data.errors;
							this.notifyErrorHelper(errors)
							break
						}
						/**
						 * Undefined Error
						 */
						default: {
							this.openNotify('error', 'common_notificationUndefinedError')
						}
					}
				})
			}
		},

		getFileFromServerReturnObject(file, type = null){
			if(!file) return

			let localItem = file

			return this.$store.dispatch('getFileFromServer', file.id)
				.then((response) => {
					console.log(response);
					let fileBase64 = response
					switch (type) {
						case 'image':
							localItem.typeBase64 = `image`
							localItem.base64 = fileBase64[file.id]
							break
						default:
							localItem.base64 = fileBase64[file.id]
							break
					}

					return localItem
				})

		},

		getFileFromServer(file){
			this.$store.dispatch('getFileFromServer', file.id)
				.then((response) => {
					let dataFile = response
					let fileBase64 = dataFile
					Object.keys(dataFile).map(item => {
						dataFile[item] = {
							id: item,
							type: file.mime_type,
							uuid: file.uuid,
							base64: fileBase64[item]
						}
					})
					this.OI.data.Files.pushDownloadFiles(dataFile)
				})
		},
	}
}
