<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_otherIncome'])"></div>
        {{$t('otherIncome_otherIncome.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="transaction-info__content">
        <div class="transaction-info__section" v-if="item">
          <div class="transaction-info__section-title"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['otherIncome_OtherIncomeDetails'])"></div>
            {{$t('otherIncome_OtherIncomeDetails.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['otherIncome_Created'])"></div>
                {{$t('otherIncome_Created.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ item.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['expenses_DocumentDate'])"></div>
                {{$t('expenses_DocumentDate.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ item.document_date | moment("DD MMM, YYYY") }}
              </div>
            </div>
          </div>

          <!--<div class="transaction-info__row">-->
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--Add an invoice-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!-- - -->
              <!--</div>-->
            <!--</div>-->

          <!--</div>-->

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['otherIncome_typeOfIncome'])"></div>
                {{$t('otherIncome_typeOfIncome.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{
                  _.find(item.expense_types,
                      function(elem) {return elem.type === EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE.value}).name
                }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['otherIncome_Total'])"></div>
                {{$t('otherIncome_Total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="item.amount"
                />
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="item"
                    :deep="'country.name'"
                />
              </div>
            </div>
            <div class="transaction-info__col w-100">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['otherIncome_comment'])"></div>
                {{$t('otherIncome_comment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    class="word-break"
                    :value="item.comment"
                />
              </div>
            </div>
          </div>

          <div class="transaction-info__row">

            <div class="transaction-info__col">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['expenses_Documents'])"></div>
                {{$t('expenses_Documents.localization_value.value')}}
              </div>
            </div>
          </div>

          <div class="custom-row transaction-info__row mt-3"
               v-if="Object.keys(OI.data.Files.data.downloadFiles).length > 0"
          >
            <div class="custom-col--16 custom-col--md-50 custom-col--sm-100"
                 v-for="(file, indexFile) in OI.data.Files.data.downloadFiles"
                 :key="indexFile"
            >
              <div class="site-document d-flex justify-content-center">
                <div class="site-document__img"
                     v-if="file[Object.keys(file)].type !== 'application/pdf'">
                  <img :src="`data:${file[Object.keys(file)].type};base64,` + file[Object.keys(file)].base64"
                       @click="showSingle(parseInt(_.first(Object.keys(file))) + 1)" alt="img">
                </div>
                <router-link v-else :to="$store.getters.GET_PATHS.getPdfFile + '/' + file[Object.keys(file)].uuid" target="_blank">
                  <div class="site-document__img">
                    <img style="width: 30px; height: 40px;"
                         src="/img/common/pdf_file_icon.svg" alt="ico">
                  </div>
                </router-link>

              </div>
            </div>

          </div>



        </div>

        <!-- all props & events -->
        <Lightbox
                escDisabled
                moveDisabled
                :visible="visible"
                :imgs="imgs"
                :index="index"
                @hide="handleHide"
        ></Lightbox>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";
  import {OtherIncome} from "../../models/OtherIncome";
  import {otherIncomeMixin} from "../../../../../mixins/otherIncomeMixins/otherIncomeMixin";
  import Lightbox from 'vue-easy-lightbox'


  export default {
    name: "InfoPopup",
    components: {
      ValueHelper,
      Modal,
      Lightbox,
    },

    props: {
      itemId: [Number, String]
    },

    mixins: [
      otherIncomeMixin
    ],

    data() {
      return {
        item: null,
        EXPENSES_SYSTEM_TYPES: EXPENSES_SYSTEM_TYPES,

        OI: new OtherIncome(),

        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0 // default: 0
      }
    },

    mounted() {

      this.$store.dispatch('getOtherIncome', this.itemId).then(response => {
        this.item = this.getRespData(response)
        this.OI.setItem(this.item)


        if(this.item.file_entities && this.item.file_entities.length > 0 && this.item.file_entities[0].extension !== 'pdf'){
          this.getFileFromServer(this._.find(this.item.file_entities, {type: 'small_image'}))
        }

        if(this.item.file_entities && this.item.file_entities.length > 0 && this.item.file_entities[0].extension === 'pdf'){
          this.getFileFromServer(this._.find(this.item.file_entities, {extension: 'pdf'}))
        }
      }).catch(error => this.createErrorLog(error))
    },

    methods: {

      showSingle(id) {

        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })

      },

      handleHide() {
        this.visible = false
      },

      show() {
        this.visible = true
      },
    }

  }

</script>

<style lang="scss" scoped>
  .site-document__img{
    min-width: 65px;
  }
</style>
