<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line ">
      <div class="content-tabs content-tabs--separator">

      </div>
      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                :showFilter="showFilter"
                @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
<!--        <DefaultCheckbox-->
<!--            :label="$t('common_selectAll.localization_value.value')"-->
<!--            @input="(val) => $emit('selectAll', val)"-->
<!--        />-->
      </div>
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
        <router-link :to="$store.getters.GET_PATHS.incomeOtherIncomeCreate">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "OtherIncomeHead",
    components: {
      // DefaultCheckbox,
      ToggleFilterButton,
      PlusIconSVG,
      MainButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    created() {

    },

    data() {
      return {}
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>

