<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_Id',])"></div>
        <DefaultInput
                :label="$t('otherIncome_Id.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_Created',])"></div>
        <DatePickerDefault
                :label="$t('otherIncome_Created.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    range
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['otherIncome_typeOfIncome'])"></span>

        <DefaultSelect
            :options="incomeTypes"
            :optionsLabel="'name'"
            :label="$t('otherIncome_typeOfIncome.localization_value.value')"
            @change="(val) => {setOtherIncomeType(val)}"
            :selected="incomesTypeItem"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>-->

<!--        <DefaultSelect-->
<!--            :options="payees"-->
<!--            :optionsLabel="'name'"-->
<!--            :label="'Payee'"-->
<!--            @change="(val) => {setPayee(val)}"-->
<!--            :selected="payeeItem"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_country'])"></span>

        <DefaultSelect
            :options="countries"
            :optionsLabel="'name'"
            :label="$t('common_country.localization_value.value')"
            @change="(val) => {setCountry(val)}"
            :selected="countryItem"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>-->

<!--        <DefaultSelect-->
<!--            :options="warehouses"-->
<!--            :optionsLabel="'name'"-->
<!--            :otherValue="'currentTranslation'"-->
<!--            :label="'Warehouses'"-->
<!--            @change="(val) => {setWarehouse(val)}"-->
<!--            :selected="warehouseItem"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_DocumentDate',])"></div>
        <DatePickerDefault
                :label="$t('expenses_DocumentDate.localization_value.value')"
                v-model="documentDate"
        >
          <template slot="body">
            <date-picker
                    v-model="documentDate"
                    range
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_Total',])"></div>
        <DefaultInput
                :label="$t('otherIncome_Total.localization_value.value')"
                :type="'text'"
                v-model="total"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_comment',])"></div>
        <DefaultInput
                :label="$t('otherIncome_comment.localization_value.value')"
                :type="'text'"
                v-model="comment"
        />
      </div>




    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../../../staticData/staticVariables";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "OtherIncomeFilter",
    components: {
      DefaultSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc],

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        documentDate: this.filterGetParams.documentDate ? this.generateFilterDate(this.filterGetParams.documentDate, 'MM/DD/YY') : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',
        total: this.filterGetParams.total ? this.filterGetParams.total : '',
        comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',
        incomesType: this.filterGetParams.incomesType ? this.filterGetParams.incomesType : '',
        country: this.filterGetParams.country ? this.filterGetParams.country : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        options: [],
        countries: [],
        incomeTypes: [],

        warehouseItem: [],
        countryItem: [],
        incomesTypeItem: [],
        bankTypeItem: [],
        payeeItem: [],

        filterCounts: [
          'date',
          'documentDate',
          'id',
          'name',
          'total',
          'comment',
          'incomesType',
          'country',

          'userName',
          'userId',
        ],
      }
    },

    created() {
      this.$store.dispatch('getOtherIncomeCreate').then(response => {

        let respData = this.getRespData(response)
        this.countries = respData.countries
        this.incomeTypes = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE.value]
      }).catch(error => this.createErrorLog(error))
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.documentDate = newVal.documentDate ? this.generateFilterDate(newVal.documentDate, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
        this.total = newVal.total ? newVal.total : ''
        this.comment = newVal.comment ? newVal.comment : ''
        this.bank = newVal.bank ? newVal.bank : ''
        this.incomesType = newVal.incomesType ? newVal.incomesType : ''
        this.payee = newVal.payee ? newVal.payee : ''
        this.warehouse = newVal.warehouse ? newVal.warehouse : ''
        this.country = newVal.country ? newVal.country : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.documentDate = this.changeDateParams(this, 'documentDate')

        this.$emit('changeFilter', data)
      },

      setWarehouse(val) {
        this.warehouse = val.id
        this.warehouseItem = val
      },

      setCountry(val) {
        this.country = val.id
        this.countryItem = val
      },

      setOtherIncomeType(val) {
        this.incomesType = val.id
        this.incomesTypeItem = val
      },
    },
  }
</script>

<style scoped>

</style>
