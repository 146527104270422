<template>
  <OtherIncomeTableUser
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @resetFilter="resetFilter"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
  />
</template>

<script>
  import OtherIncomeTableUser from "./OtherIncomeTableUser/OtherIncomeTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "OtherIncomeTable",
    components: {
      OtherIncomeTableUser,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getOtherIncomeForPage,
        page: 1,
        onePage: 1,

        filterDate: [],
        filterDocumentDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterName: '',
        filterTotal: '',
        filterComment: '',
        filterIncomesType: '',
        filterCountry: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          documentDate: this.$route.query.documentDate,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          name: this.$route.query.name,
          total: this.$route.query.total,
          comment: this.$route.query.comment,
          incomesType: this.$route.query.incomesType,
          country: this.$route.query.country,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOtherIncomePage', true)
        this.$store.dispatch('fetchOtherIncome', url).then(() => {
          this.$store.commit('setNextOtherIncomePage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['user'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'ExpenseId',
            filterTotal: 'ExpenseAmount',
            filterComment: 'ExpenseComment',
            // filterWarehouse: 'ExpenseWarehouseId',
            filterCountry: 'ExpenseCountryId',
            filterIncomesType: 'byDefaultIncomeType',
          },
        )

        myQuery.where('ExpenseType', 'income')

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          myQuery.whereIn('ExpenseCreatedAt', date)
        }

        if (this.filterDocumentDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDocumentDate, 'YYYY-MM-DD')
          myQuery.whereIn('ExpenseDocumentDate', date)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }


  }
</script>

<style scoped>

</style>
